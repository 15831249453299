<template>
  <div class="about" id="aboutUs">
    <Header></Header>
    <div class="bannerTitle">
        <p>회사 정보</p>
        <p>ABOUT ICLOCKWORK</p>
    </div>
    <section>
      <div class="company-info">
        <div class="info-content" id="info">
          <p class="title animate__animated animate__fadeIn">개 요</p>
          <p class="para1_1 animate__animated animate__fadeIn" v-show="delay1">
            아이클럭워크는 꿈과 열정으로 넘치는 청년들이 다양한 재미를 담은
            게임들로 사용
          </p>
          <p class="para1_2 animate__animated animate__fadeIn" v-show="delay2">
            자들을 만나 뵙고자 모이게 되였습니다.캐주얼에서
            <span>RPG</span> 까지 폭넓은 장르의 게임들
          </p>
          <p class="para1_3 animate__animated animate__fadeIn" v-show="delay3">
            을 운영하고 있으며 최고의 서비스를 제공하기 위해 최선을 다하고
            있습니다.
          </p>
          <div class="para2">
            <p
              class="para2_1 animate__animated animate__fadeIn"
              v-show="delay4"
            >
              항상 사용자들의 목소리에 귀 기울일 것을 약속드리며 저희 게임을
              통해 조금 더 즐
            </p>
            <p
              class="para2_2 animate__animated animate__fadeIn"
              v-show="delay5"
            >
              거워지면 좋겠습니다.
            </p>
          </div>
        </div>
      </div>
      <div class="position">
          <div class="line"></div>
          <div class="position-content p1 animate__animated animate__fadeInRight" id="p1">
            <p>주 소</p>
            <p>서울특별시 금천구 가산디지털2로 98, 2동 1107호</p>
          </div>
          <div
            class="position-content p2 animate__animated animate__fadeInRight"
            v-show="delay6"
            id="p2"
          >
            <p>연 락 인</p>
            <p>Aiden.Kim</p>
          </div>
          <div
            class="position-content p3 animate__animated animate__fadeInRight"
            v-show="delay7"
            id="p3"
          >
            <p>이 메 일</p>
            <p>dj.kim@iclockwork.com</p>
          </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      dealy: false,
      delay1: false,
      delay2: false,
      delay3: false,
      delay4: false,
      delay5: false,
      delay6: false,
      delay7: false,
      scroll: '',
    };
  },
  mounted() {
    setTimeout(() => {
      this.delay1 = true;
    }, 500);
    setTimeout(() => {
      this.delay2 = true;
    }, 1000);
    setTimeout(() => {
      this.delay3 = true;
    }, 1500);
    setTimeout(() => {
      this.delay4 = true;
    }, 2000);
    setTimeout(() => {
      this.delay5 = true;
    }, 2500);
    this.$nextTick(() => {
      if(this.$route.query.from == 'footer') {
        this.delay()
        this.toBottom()
      } 
    })
     window.addEventListener('scroll', this.handleScroll, true)
  },
  methods: {
    delay() {
      setTimeout(() => {
          this.delay6 = true;
        }, 500);
         setTimeout(() => {
          this.delay7 = true;
        }, 1000);
    },
   // 滚动到最底部
    toBottom(){
      let height = document.body.scrollHeight;
      window.scrollTo ({top:height,behavior: 'smooth'})
    },
    handleScroll() {
      let scrollHeight = document.documentElement.scrollTop;
      let pOffsetTopHeight = document.getElementById('p1').offsetHeight;
      if(scrollHeight >= 3*pOffsetTopHeight/2) {
        this.delay()
      }
    },
  }
};
</script>

<style lang="scss" scope>
.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  max-height: 100%;
  background: url("../assets/img/aboutus/bg.png") no-repeat;
  background-size: cover;
  background-position: center center;
}
.bannerTitle {
  position: absolute;
  top: 7.2%;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  p:nth-child(1) {
    font-size: .53rem;
  }
  p:nth-child(2) {
    font-family: 'Mircosoft YaHei';
    font-size: .21rem;
    line-height: .2667rem;
    letter-spacing: 1px;
  }
}
section {
  flex: 1;
  width: 100%;
  margin-bottom: 1.12rem;
  .company-info {
    width: 100%;
    height: 5.52rem;
    margin: 1.45rem 0 0.7rem 0;
    background: url('../assets/img/aboutus/txt-bg.png');
    background-size: cover;
    
    .info-content {
      margin: 0 auto;
      width: 9rem;
      font-size: 0.24rem;
      font-weight: 500;
      color: #3c3c3c;
      text-align: center;
      .title {
        padding: 0.5333rem 0 0.4rem 0;
        font-family: NotoSansKR-Medium;
        font-size: 0.5rem;
        color: #f08200;
        animation: fadeIn;
        animation-duration: 2s;  
      }
      p {
        font-family: 'NotoSansKR-Regular';
        line-height: .4rem;
      }
      .para1_1 {
        // animation:fadeIn 2s;
        // -webkit-animation:fadeIn 2s; /*Safari and Chrome*/
        animation: fadeIn;
        animation-duration: 1s;
      }
      .para1_2 {
        animation: fadeIn;
        animation-duration: 1s;
      }
      .para1_3 {
        animation: fadeIn;
        animation-duration: 1s;
      }
      .para2 {
        padding: 0.4rem 0;
      }
      .para2_1 {
        animation: fadeIn;
        animation-duration: 1s;
      }
      .para2_2 {
        animation: fadeIn;
        animation-duration: 1s;
      }
    }
  }
  .position {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 16rem;
    height: 4.65rem;
    margin: 0 auto;
    .line {
      width: 11rem;
      height: 2px;
      background: #f08200;
      border-radius: 4px;
    }
    .position-content {
      text-align: center;
      p:nth-child(1) {
        font-family: 'NotoSansKR-Regular';
        font-size: 0.3rem;
        color: #a8a6a5;
        line-height: 0.76rem;
      }
      p:nth-child(2) {
        width: 11rem;
        border: 1px solid #bababb;
        background: rgba(255, 255, 255, 0.1);
        font-size: 0.24rem;
        color: #ffffff;
        line-height: 0.76rem;
      }
    }
    .p1 {
      animation: fadeInRight;
      animation-duration: 1s; /* don't forget to set a duration! */
    }
    .p2 {
      animation: fadeInRight;
      animation-duration: 1s; /* don't forget to set a duration! */
    }
    .p3 {
      // -webkit-animation-delay: 2s;
      // -moz-animation-delay: 2s;
      // -o-animation-delay: 2s;
      // animation-delay: 2s;
      animation: fadeInRight;
      animation-duration: 1s; /* don't forget to set a duration! */
    }
  }
}
footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 700px;
  height: 110px;
  font-family: 'Mricosoft YaHei',Arial,微软雅黑;
  .copy-top li {
    color: #969597 !important;
  }
  .copy-top li a {
    color: #969597 !important;
  }
  .copy-bottom span {
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
}
</style>